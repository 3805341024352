<template>
    <section class="row bg-light text-body px-0 py-3 py-md-5 order-1">
        <a id="work"></a>            
        <div class="col-12 px-3 d-flex justify-content-center align-center">
            <h2 class="h2 d-inline-block text-xl-center my-4">Employment</h2>
        </div>

        <div class="col-12">
            <h5 class="d-block mb-2 text-center font-weight-bold">Present Year</h5>
        </div>
        <div class="col-12 d-flex justify-content-center ">
            <div class="row container timeline">

                <div class="col-12">   
                    <div class="row align-items-center text-center">
                        <div class="col-md-5 order-2 order-md-1">
                            
                        </div>
                        <div class="col-md-2 line position-relative order-1 order-md-2 text-danger">
                            <strong>Apr '21 <span class="mx-1">&nbsp;</span> Present</strong>
                        </div>
                        
                        <div class="col-md-5 order-3">
                            <img class="img-fluid mb-2 px-5" src="img/network-n.jpg">
                            <h5 class="font-weight-bold mb-1 job-title text-info">Senior Developer @ Network N</h5>
                            <p class="mb-0 font-weight-bold">
                                
                            </p>
                        </div>
                    </div>
                    <hr class="d-block d-md-none" />
                </div>

                <div class="col-12">   
                    <div class="row align-items-center text-center">
                        <div class="col-md-5 order-2 order-md-1">
                            <img class="img-fluid mb-2 px-5" src="img/ontoast.png">
                            <h5 class="font-weight-bold mb-1 job-title text-info">Full Stack Developer @ OnToast</h5>
                            <p class="mb-0 font-weight-bold">
                                Building web projects from design brief concept to implementing full functionality of both front and backend systems utilising PHP &amp; Laravel.
                            </p>
                        </div>
                        <div class="col-md-2 line position-relative order-1 order-md-2 text-danger">
                            <strong>Aug '19 <span class="mx-1">&nbsp;</span> Apr '21</strong>
                        </div>
                        
                        <div class="col-md-5 order-3">
                            
                        </div>
                    </div>
                    <hr class="d-block d-md-none" />
                </div>

                <div class="col-12">   
                    <div class="row align-items-center text-center">
                        <div class="col-md-5 order-2 order-md-1">
                            
                        </div>
                        <div class="col-md-2 line position-relative order-1 order-md-2 text-danger">
                            <strong>June '15 <span class="mx-1">&nbsp;</span> Aug '19</strong>
                        </div>
                        
                        <div class="col-md-5 order-3">
                            <img class="img-fluid mb-2 px-5" src="img/sockshop.jpg">
                            <h5 class="font-weight-bold mb-1 job-title text-info">Web Developer @ Sockshop</h5>
                            <p class="mb-0 font-weight-bold">
                                As part of the web team, I worked on a custom PHP eccomerce platform for over 4 years in which time we integrated various new features and redeveloped the frontend to utilise bootstrap.
                            </p>
                        </div>
                    </div>
                    <hr class="d-block d-md-none" />
                </div>

                <div class="col-12">   
                    <div class="row align-items-center text-center">
                        <div class="col-md-5 order-2 order-md-1">
                            <img class="img-fluid mb-2 px-5" src="img/freelance.png">
                            <h5 class="font-weight-bold mb-1 job-title text-info">Freelance</h5>
                            <p class="mb-0 font-weight-bold">
                                Website development using Wordpress framework. <br>
                            Clients include dentists, doctors, designers, bands &amp; more.</p>
                        </div>
                        <div class="col-md-2 line position-relative order-1 order-md-2 text-danger">
                            <strong>Aug '11 <span class="mx-1">&nbsp;</span> Present</strong>
                        </div>
                        
                        <div class="col-md-5 order-3">
                            
                        </div>
                    </div>
                    <hr class="d-block d-md-none">
                </div>

                <div class="col-12">   
                    <div class="row align-items-center text-center">
                        <div class="col-md-5 order-2 order-md-1">
                            
                        </div>
                        <div class="col-md-2 line position-relative order-1 order-md-2 text-danger">
                            <strong>Sept '10 <span class="d-inline-block mx-1"> &nbsp; </span> May '11</strong>
                        </div>
                        
                        <div class="col-md-5 order-3">
                            <img class="img-fluid mb-2 px-5" src="img/uniofhudd.jpg">
                            <h5 class="font-weight-bold mb-1 job-title text-info">Secondary ICT Teacher</h5>
                            <p class="mb-0 font-weight-bold">
                                Back to University! I completed my PGCE in Secodary ICT and qualified to teach.
                            </p>
                        </div>
                    </div>
                    <hr class="d-block d-md-none">
                </div>

                <div class="col-12">   
                    <div class="row align-items-center text-center">
                        <div class="col-md-5 order-2 order-md-1">
                            <img class="img-fluid mb-2 px-5" src="img/firstfound.jpg">
                            <h5 class="font-weight-bold mb-1 job-title text-info">FirstFound</h5>
                            <p class="mb-0 font-weight-bold">Fresh out of University I worked at SEO company First Found as an SEO consultant, implementing on-site optimisation of client websites.</p>
                        </div>
                        <div class="col-md-2 line position-relative order-1 order-md-2 text-danger">
                            <strong>Sept '09 <span class="mx-1">&nbsp;</span> May '10</strong>
                        </div>
                        
                        <div class="col-md-5 order-3">
                            
                        </div>

                    </div>
                    <hr class="d-block d-md-none" />
                </div>

            </div>
        </div>

        <div class="col-12 text-center font-weight-bold pt-3 pt-md-5 mb-2 mb-md-5">
            <p class="mt-3 mt-md-5"><a target="_blank" class="text-body" href="https://www.linkedin.com/in/anton-hutchinson-72718114">See my LinkedIn for Education Information</a> <i class="fas fa-external-link-alt"></i></p>
        </div>
    </section>
  
</template>

<script>
export default {
    name: 'Employment',
}
</script>

<style>

</style>