<template>
    <div class="row justify-content-center text-center">
        <div class="col-12 col-md-6">
            <div class="d-flex flex-column flex-lg-row align-items-center mt-4 mb-2 justify-content-center">
                <h2 class="h3 text-xl-center d-inline-block d-inline-flex align-items-end">
                    Time Finance
                </h2>   
                <small class="ml-2 align-self-stretch d-flex justify-content-center align-items-center"><span class="d-none d-lg-block mr-2">-</span> Work Project</small>
            </div>
          
           <p><a class="text-white" href="https://timefinance.com" target="_blank">timefinance.com</a> <i class="fas fa-external-link-alt"></i></p>
            <h3>Features</h3>
            <p>
                - Built on OctoberCMS with Bootstrap <br>
                - A full custom CMS system for general pages <br>
                - Blog and Articles section <br>
                - Custom admin system for business products and services <br>
                - Vehicle management system <br>
                - Vehicle Search &amp; Filtering functionaity <br>
                - CAP HPI Web Service Integration<br>
                - Adobe Sign Documents<br>
            </p> 
        </div>
        <div class="col-12 col-md-6 col-xl-5 py-4">
            <ul class="nav nav-tabs" id="TimeTabs" role="tablist">
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 1">
                    <a class="nav-link rounded-top" :class="activeTab == 1 ? 'active' : ''">Frontend</a>
                </li>
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 2">
                    <a class="nav-link rounded-top" :class="activeTab == 2 ? 'active' : ''">Backend</a>
                </li>
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 3">
                    <a class="nav-link rounded-top" :class="activeTab == 3 ? 'active' : ''">Car Search</a>
                </li>
            </ul>
            <div class="tab-content" id="timeTabContent">
                <div class="tab-pane fade" :class="activeTab == 1 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/time-finance.jpg" @click="launchModal('/img/portfolio/time-finance.jpg')">
                </div>
                <div class="tab-pane fade" :class="activeTab == 2 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/time-finance-backend.jpg" @click="launchModal('/img/portfolio/time-finance-backend.jpg')">
                </div>
                <div class="tab-pane fade" :class="activeTab == 3 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/time-finance-cars.jpg" @click="launchModal('/img/portfolio/time-finance-cars.jpg')">
                </div>
            </div>
        </div>
    </div>  
</template>

<script>

export default {
    name: 'Project-TimeFinance',

    data(){
        return{
            activeTab: 1,
        }
    },

    methods: {

        launchModal(image){
            Event.$emit('openModal', image)
        }

    },

}
</script>

<style scoped>
    .nav-item { margin-right: 2px;}
    .nav-tabs {border-bottom: solid 1px rgb(128 142 156);}
    .nav-link
    {
        color: #fff;
        background: unset;
        border: solid 1px rgb(128 142 156);
    }

    .nav-link.active {
        background: rgb(128 142 156);
        border: solid 1px rgb(128 142 156);
        color: #fff;
    }

    .tab-content {
        padding: 0.5rem .2rem;
       
    }

    .cursor-pointer { cursor: pointer;}

</style>