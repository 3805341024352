<template>
    <section class="row contact bg-info py-3">
        <a id="contact"></a>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 px-3 d-flex justify-content-center d-md-block">
                    <h2 class="h4 mb-4">Connect With Me</h2>
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-center d-md-block mb-3 mb-md-0">
                    <img class="d-block ml-md-4 rounded-circle img-fluid tonface" 
                        src="https://media.licdn.com/dms/image/D4E03AQGNVWZ_7i0UZg/profile-displayphoto-shrink_100_100/0/1708558219948?e=1714003200&v=beta&t=BqXwstS9croOuySopKMDinycAIMnd6v6mU8bfjFjS44">
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex justify-content-center justify-content-md-end text-center">
                        <span class="d-block mx-2 mx-md-4 mb-2 mb-md-0 text-center text-md-left">
                            <a target="_blank" href="https://twitter.com/tonhutch">
                            <i class="fab fa-twitter"></i> TonHutch</a>
                        </span>
                        <span class="d-block mx-2 mx-md-4 mb-2 mb-md-0 text-center text-md-left">
                            <a target="_blank" href="https://www.linkedin.com/in/anton-hutchinson-72718114/">
                            <i class="fab fa-linkedin"></i> LinkedIn</a>
                        </span>
                        <span class="d-block mx-2 mx-md-4 mb-2 mb-md-0 text-center text-md-left">
                            <a target="_blank" href="https://github.com/TonnnnUK">
                            <i class="fab fa-github"></i> Github</a>
                        </span>
                    </div> 
                </div> 
            </div>
        </div>
    </section>  
</template>

<script>
export default {
    name: 'Connect',
}
</script>

<style>

</style>