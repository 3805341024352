<template>

<transition name="modal">
    <div v-if="showModal">
        <div class="modal-mask" :style="showModal ? 'opacity: 1' : 'opacity: 0'">
            <div class="modal-wrapper">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" @click="showModal = false">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <img class="img-fluid" :src="imageSrc"  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</transition>
</template>

<script>

export default {
    data(){
        return {
            showModal: '',
            imageSrc: ''
        }
    },
    mounted(){
        Event.$on('openModal', (src) => { 
            this.imageSrc = src
            this.showModal = true
        })
    },

}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  transition: all .3s ease-in-out;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.modal-enter-active {
  transition: all .3s ease;
}
.modal-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.modal-enter, .modal-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>