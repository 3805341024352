<template>
    <div class="row justify-content-center text-center">
        <div class="col-12 col-md-6">
            <div class="d-flex flex-column flex-lg-row align-items-center mt-4 mb-2 justify-content-center">
                <h2 class="h3 text-xl-center d-inline-block d-inline-flex align-items-end">
                    Insite Energy
                </h2>   
                <small class="ml-2 align-self-stretch d-flex justify-content-center align-items-center"><span class="d-none d-lg-block mr-2">-</span> Work Project</small>
            </div>
            
            <p><a class="text-white" href="https://insite-energy.co.uk" target="_blank">insite-energy.co.uk</a>  <i class="fas fa-external-link-alt"></i></p>
            <p>
                Energy Billing company Insite Energy required a full web CMS rebuild to replace an aging Wordpress website.
            </p> 
            <p>
                The project included full development of a custom CMS using built using custom blocks to give the client control of the page designs. 
            </p>
            <h3>Features</h3>
            <p>
                - Built on OctoberCMS &amp; Bootstrap CSS <br>
                - Custom backend to manage location development tenants<br>
                - FAQ / Knowledge base management system<br>
            </p> 
        </div>
        <div class="col-12 col-md-6 col-xl-5 py-4">
            <img class="img-fluid" src="/img/portfolio/insite-energy.jpg" >
        </div>
    </div>  
</template>

<script>

export default {
    name: 'Project-Insite',

}
</script>

<style scoped>

</style>