<template>
    <div class="row justify-content-center text-center">
        <div class="col-12 col-md-6">
            <div class="d-flex flex-column flex-lg-row align-items-center mt-4 mb-2 justify-content-center">
                <h2 class="h3 text-xl-center d-inline-block d-inline-flex align-items-end">
                    FPS Monitor Overwolf App
                </h2>   
                <small class="ml-2 align-self-stretch d-flex justify-content-center align-items-center"><span class="d-none d-lg-block mr-2">-</span> Work Project</small>
            </div>
            
            <p><a class="text-white" href="https://www.overwolf.com/app/Network_N_Media-PC_Game_Benchmark_-_FPS_Monitor" target="_blank">overwolf.com</a> <i class="fas fa-external-link-alt"></i></p>
            <p>
                I developed an Overwolf app, allowing gamers to monitor their FPS performance as they play games. This app tied in with the PC Game Benchmark system 
                utilising a custom API to gather data regarding the users' PC spec and the games system requirements. FPS stability is monitored for the duration of gameplay 
                and a performance analysis is displayed upon closing a game.
            </p>
            <p>
                This system was developed on top of the Overwolf system in JavaScript and utilises IndexDB, API calls to PCGB for retrieving and storing gameplay FPS data. 
                PCGB outlines expected FPS performance per game based on the data gathered from the Overwolf app.
            </p> 
        </div>
        <div class="col-12 col-md-6 col-xl-5 py-4">
            <ul class="nav nav-tabs" id="OverwolfTabs" role="tablist">
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 1">
                    <a class="nav-link rounded-top" :class="activeTab == 1 ? 'active' : ''">Overwolf Page</a>
                </li>
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 2">
                    <a class="nav-link rounded-top" :class="activeTab == 2 ? 'active' : ''">Screenshot</a>
                </li>
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 3">
                    <a class="nav-link rounded-top" :class="activeTab == 3 ? 'active' : ''">Screenshot</a>
                </li>
            </ul>
            <div class="tab-content" id="overwolfTabContent">
                <div class="tab-pane fade" :class="activeTab == 1 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/overwolf-app-3.png" @click="launchModal('/img/portfolio/overwolf-app-3.png')">
                </div>
                <div class="tab-pane fade" :class="activeTab == 2 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/overwolf-app-1.png" @click="launchModal('/img/portfolio/overwolf-app-1.png')">
                </div>
                <div class="tab-pane fade" :class="activeTab == 3 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/overwolf-app-2.png" @click="launchModal('/img/portfolio/overwolf-app-2.png')">
                </div>
            </div>        
        </div>
    </div>  
</template>

<script>

export default {
    name: 'Project-Overwolf',

    data(){
        return{
            activeTab: 1,
        }
    },

    methods: {

        launchModal(image){
            Event.$emit('openModal', image)
        }

    },


}
</script>

<style scoped>
    .nav-item { margin-right: 2px;}
    .nav-tabs {border-bottom: solid 1px rgb(128 142 156);}
    .nav-link
    {
        color: #fff;
        background: unset;
        border: solid 1px rgb(128 142 156);
    }

    .nav-link.active {
        background: rgb(128 142 156);
        border: solid 1px rgb(128 142 156);
        color: #fff;
    }

    .tab-content {
        padding: 0.5rem .2rem;
       
    }

     .cursor-pointer { cursor: pointer;}


</style>