<template>
<section class="row work bg-dark pt-3 pb-0 order-1">
    <a id="projects"></a>
        <div class="col-12">
            <div class="row py-2 py-md-4 align-items-center controls">
                <div class="col-2 text-center">
                    <a class="position-relative text-white d-flex flex-column flex-md-row justify-content-center align-items-center" href="#projectsControls" role="button" data-slide="prev">
                        <i class="fa fa-arrow-left mr-2"></i>
                        <span>Previous</span>
                    </a>
                </div>
                <div class="col-8 text-center">
                    <h2>PROJECTS</h2>
                </div>
                <div class="col-2 text-center">
                    <a class="position-relative text-white d-flex flex-column flex-md-row justify-content-center align-items-center" href="#projectsControls" role="button" data-slide="next">
                        <span class="order-2 order-md-1">Next</span>
                        <i class="fa fa-arrow-right ml-2 order-1 order-md-2"></i>
                    </a>
                </div>
            </div>
            <div class="row px-0">                        
                <div class="col-12 col-md bg-dark py-2">

                    <div id="projectsControls" class="carousel slide" data-interval="false" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <Project-Fns></Project-Fns>
                            </div>
                            <div class="carousel-item">
                                <Project-Overwolf></Project-Overwolf>
                            </div>
                            
                            <div class="carousel-item">
                                <Project-Pcgb></Project-Pcgb>
                            </div>
                            
                            <div class="carousel-item">
                                <Project-TimeFinance></Project-TimeFinance>
                            </div>
                            <div class="carousel-item">
                                <Project-Hpl></Project-Hpl>
                            </div>
                            <div class="carousel-item">
                                <Project-Insite></Project-Insite>
                            </div>
                        </div>

                    </div>
                    
                    <hr class="d-block d-md-none" />
                </div>
            </div>
            
        </div>

        <Modal></Modal>

    </section>  
</template>

<script>
import ProjectFns from './Projects/Project-Fns.vue'
import ProjectPcgb from './Projects/Project-Pcgb.vue'
import ProjectOverwolf from './Projects/Project-Overwolf.vue'
import ProjectTimeFinance from './Projects/Project-Timefinance.vue'
import ProjectHpl from './Projects/Project-Hpl.vue'
import ProjectInsite from './Projects/Project-Insite.vue'
import Modal from './Projects/Modal.vue'

export default {
    name: 'Projects',
    components: {
        ProjectFns,
        ProjectPcgb,
        ProjectOverwolf,
        ProjectTimeFinance,
        ProjectHpl,
        ProjectInsite,
        Modal
    },
}
</script>

<style>
    h2, i.fa-external-link-alt { color: #a9a9a9;}
    
</style>

