<template>
    <div class="row justify-content-center text-center">
        <div class="col-12 col-md-6 position-relative">
            <div class="d-flex flex-column flex-lg-row align-items-center mt-4 mb-2 justify-content-center">
                <h2 class="h3 text-xl-center d-inline-block d-inline-flex align-items-end">
                    Fightnight Scores 
                </h2>   
                <small class="ml-2 align-self-stretch d-flex justify-content-center align-items-center"><span class="d-none d-lg-block mr-2">-</span> Personal Project</small>
            </div>

            <p><a class="text-white" href="https://fight-score.com" target="_blank">fight-score.com</a> <i class="fas fa-external-link-alt"></i></p>
            
            <p>
                I've been building a boxing community website for passionate boxing fans, a scorecard system with a difference. 
                This boxing scorecard app is for fans to score, save and share their scorecards using a unique
                'reasoning' system, fully devised and developed from scratch.
            </p> 
            <p>
                This system allows for in-depth fight data analysis and charts for visualisations of community contributions. 
            </p>
            <h3>Features</h3>
            <p>
                - Built on Laravel &amp; Bootstrap CSS <br>
                - Components using Vue &amp; jQuery functionality sprinkled in<br>
                - Laravel Livewire components integrated<br>
                - System can be used by guest or registered users<br>
                - Charting &amp; Data for visual <a class="text-white" target="_blank" href="https://fight-score.com/analysis"><u>fight analysis</u></a><br>
            </p> 

            <span class="d-inline-block try-demo mt-2 mt-md-0" style="border: solid 1px #6f6f6f">
                Hit 'Start Scoring' to try the demo 
                <span class="d-none d-md-inline"><i class="fa fa-arrow-right ml-2" style="color: #a9a9a9"></i></span>
                <span class="d-inline d-md-none"><i class="fa fa-arrow-down" style="color: #a9a9a9"></i></span>
            </span>
        </div>
        <div class="col-12 col-md-6 col-xl-5 py-4">
            <Fns-Demo></Fns-Demo>
        </div>
    </div>  
</template>

<script>

import FnsDemo from '../Demo.vue'
export default {
    name: 'Project-Fns',
    components: {
        FnsDemo,
    }
}
</script>

<style>
.try-demo {
    position: relative;
    background: rgba(0,0,0, .45);
    padding: 1rem;
}

</style>