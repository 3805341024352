<template>
    <div class="h-100 container d-flex flex-column justify-content-between px-0">
        <div class="row">
            <div class="col-12 text-center">
                <div class="collapse" id="collapseVideo">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/1vIbkYAIRZM" allowfullscreen></iframe>
                    </div>
                </div>
                <button class="bg-light border rounded mt-1" @click="switchText()"
                        data-toggle="collapse" data-target="#collapseVideo" aria-expanded="false" aria-controls="collapseVideo">
                    <i class="fab fa-youtube d-inline mr-md-1"></i> <small>{{vidText}}</small>
                </button>
            </div>
        </div>
        <div class="row no-gutters text-center justify-content-center">
            <div class="col-5 boxer-img">
                <img
                    :src="`https://res.cloudinary.com/dpelf28x1/image/upload/v1537536345/Fight%20Score/Boxer%20Avatars/gennadiy-golovkin-avatar.png`" 
                    style="width: 80px" />
            </div>
            <div class="col-2"></div>
            <div class="col-5 boxer-img">
                <img
                    :src="`https://res.cloudinary.com/dpelf28x1/image/upload/v1537536345/Fight%20Score/Boxer%20Avatars/canelo-alvarez-avatar.png`" 
                    style="width: 80px" />
            </div>
        </div>

        <div class="row no-gutters text-center justify-content-center pt-2 b2">
            <div class="col-5 b1 text-center font-weight-bold h6">
                Gennady Golovkin
            </div>
            <div class="col-2 text-center font-weight-bold">vs</div>
            <div class="col-5 b2 text-center font-weight-bold h6">
                Saul Alvarez
            </div>
            <div class="col-12 text-center">
                <hr class="my-0">
            </div>
        </div>

        <div class="row no-gutters text-center justify-content-center">
            <div class="rounds container text-center" style="min-height: unset!important">
                <div class="row dotted-bottom mb-2 justify-content-center align-items-center cursor-pointer edit-round" 
                    v-for="n in scorecard.round" 
                    :key="n"
                    @click="editRound(n)">
                    <div class="col-4 font-weight-bold h6">
                        {{scorecard.f1scorecard[n-1]}}
                    </div>
                    <div class="col-3"><small class="font-weight-bold">ROUND<br class="d-block"> {{n}}</small></div>
                    <div class="col-4 font-weight-bold h6">
                        {{scorecard.f2scorecard[n-1]}}
                    </div>

                    <div class="col-12 d-flex" v-if="scorecard.round > 0 && scorecard.f1scorecard.length > 0">
                        <span class="d-inline-block text-center text-white xsmall" :style="`width: ${scorecard.percentages1[n-1]}%; background:#004484;`">
                            {{scorecard.percentages1[n-1]}}%</span>
                        <span class="d-inline-block text-center text-white xsmall" :style="`width: ${scorecard.percentages2[n-1]}%; background:#820000;`">
                            {{scorecard.percentages2[n-1]}}%</span>
                    </div>

                    <div class="col-12 py-1 mb-2 position-relative">
                        <small><strong>Reason:</strong> {{scorecard.reasons[n-1]}}</small>
                        <tweet-button :tweetButton="scorecard.tweetbtns[n-1]" @click.stop=""></tweet-button>
                    </div>

                    <div class="col-12 px-0" v-if="scorecard.comments[n-1] != 'None'">
                        <small class="d-block w-100 p-2 bg-light text-left"><strong>Comments:</strong> {{scorecard.comments[n-1]}}</small>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="row no-gutters my-2 my-md-4" v-if="scorecard.round == 0">
                <div class="col-12 text-center pulse">
                    <p class="font-weight-bold ">Press the "Start Scoring" button <br>below to begin.</p>   
                </div>
            </div>
            <div class="col-12 cursor-pointer py-2 py-md-4 text-center h6 mb-0 add-round" v-if="started" @click="scoreRound()">
                <span class="d-block animate__animated animate__pulse animate__infinite bg-green text-white p-2">
                    <i class="far fa-plus-square"></i> Score Round {{scorecard.round+1}}
                </span>
                <div class="editMsg font-weight-bold mt-2 d-block position-relative small" v-if="scorecard.round > 0">
                    <i class="fas fa-hand-pointer"></i> Tap on a round to edit your scores
                </div>
            </div>

            <div class="row no-gutters">
                <div class="col-12 bg-green container text-white text-center py-2" 
                    v-if="!started"
                    @click="startScorecard()">
                    <div class="row no-gutters">
                        <div ref="startButton" class="col animate__animated animate__pulse animate__infinite animate__delay-5s text-uppercase">
                        <i tabindex="3" aria-hidden="true" class="far fa-bell"></i> <span>Start Scoring</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 bg-dark text-white text-center py-2">
                    <div class="row no-gutters justify-content-around ">
                        <div class="col-5 h6">
                            {{f1total}}
                        </div>
                        <div class="col-2">
                            Totals
                        </div>
                        <div class="col-5 h6">
                            {{f2total}}
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-5 h6">
                            <small>{{scorecard.f1rounds}}</small> 
                        </div>
                        <div class="col-2">
                            <small>Rounds</small>
                        </div>
                        <div class="col-5 h6">
                            <small>{{scorecard.f2rounds}}</small>
                        </div>
                        <div class="col-12" v-if="scorecard.draws > 0">
                            <small>{{scorecard.draws}} even</small>
                        </div>
                    </div>

                    <div class="row no-gutters justify-content-center no-gutters">
                        <div class="col-11 d-flex small text-white mt-4">
                            <span data-html2canvas-ignore="" 
                                class="mock-slider position-absolute d-block py-2 bg-white border border-dark p2" 
                                :style="`left: ${f1Percent}%; width: 10px; height: 35px; bottom: -8px; border: 2px solid #fff !important; margin-left: -5px;`">
                            </span> 
                            <span class="d-inline-block bg-blue float-left" :style="`width: ${f1Percent}%;`">{{f1Percent}}%</span> 
                            <span class="d-inline-block bg-red float-left" :style="`width: ${f2Percent}%;`">{{f2Percent}}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <demo-score-popout 
            v-show="showPopup"
            @addScores="addScores" 
            @editScores="editScore"
            @closeModal="closeModal" 
            :round="scorecard.round" 
            :class="popupClasses"
            class="position-absolute"
            ref="scoreModal" />

       
    </div>
</template>

<script>
import DemoPopout from './Demo-Popout.vue'
export default {
    name: 'demo-scorecard',
    components: {
        'demo-score-popout': DemoPopout,
    },
    data(){
        return {
            fight: {

            },
            scorecard: {
                cardId: 0,
                f1scorecard: [],
                f2scorecard: [],
                f1rounds: 0,
                f2rounds: 0,
                draws: 0,
                reasons: [],
                percentages1: [],
                percentages2: [],
                comments: [],
                roundwinner: [],
                round: 0,
                tweetbtns: []
            },
            started: false,
            showPopup: false,
            popupClasses: false,
            vidText: 'View Video'
        }
    },

    computed: {
        f1total: function() {
            return this.scorecard.f1scorecard.reduce(this.getSum, 0)
        },

        f2total: function() {
            return this.scorecard.f2scorecard.reduce(this.getSum, 0)
        },

        f1Percent: function(){
            if (this.scorecard.percentages1.length == 0){
                return 50;
            }
            let sum = this.scorecard.percentages1.reduce(this.getSum, 0)
            return (sum / this.scorecard.round).toFixed(2)
        },
        f2Percent: function(){
            if (this.scorecard.percentages2.length == 0){
                return 50;
            }
            let sum = this.scorecard.percentages2.reduce(this.getSum, 0)
            return (sum / this.scorecard.round).toFixed(2)
        },
    },

    methods: {

        getSum(total, num) {
            return total + Math.round(num);
        },

        startScorecard(){
            this.started = true
            this.scoreRound()
        },

        scoreRound(){
            this.showPopup = true
            this.popupClasses = 'animate__animated animate__slideInUp top0 left0'
        },

        addScores(data){
            this.scorecard.f1scorecard.push(data.f1)
            this.scorecard.f2scorecard.push(data.f2)
            this.scorecard.reasons.push(data.chosenReason)
            this.scorecard.percentages1.push(data.percentage)
            this.scorecard.percentages2.push(100-data.percentage)
            this.determineRoundWinners()
            
            if ( data.comment != ''){
                this.scorecard.comments.push(data.comment)
            } else {
                this.scorecard.comments.push('None')
            }

            this.scorecard.round+=1
        },

        determineRoundWinners(){
            let $this = this
            $this.scorecard.f1rounds = 0
            $this.scorecard.f2rounds = 0
            $this.scorecard.draws = 0
            this.scorecard.roundwinner = []
            $this.scorecard.f1scorecard.forEach(function(item, index) {
                let theWinner = ''

                if ( item > $this.scorecard.f2scorecard[index]) { 
                    theWinner = '1'
                    $this.scorecard.f1rounds++
                }
                else if ( item < $this.scorecard.f2scorecard[index]) { 
                    theWinner = '2'                
                    $this.scorecard.f2rounds++
    
                }
                else { 
                    theWinner = 'd'
                    $this.scorecard.draws++
                }

                $this.scorecard.roundwinner.push(theWinner)
            });

        },

        editRound(key){   
            let values = {
                round: key,
                f1Score: this.scorecard.f1scorecard[key-1],
                f2Score: this.scorecard.f2scorecard[key-1],
                percent: this.scorecard.percentages1[key-1],
                reason: this.scorecard.reasons[key-1],
                comment: this.scorecard.comments[key-1],
            }

            this.showPopup = true


            this.$refs.scoreModal.editRound(values);
            this.popupClasses = 'animate__animated animate__slideInUp top0'
        },

        editScore(values){
            this.scorecard.f1scorecard[values.round-1] = values.f1
            this.scorecard.f2scorecard[values.round-1] = values.f2
            this.scorecard.percentages1[values.round-1] = values.percentage
            this.scorecard.percentages2[values.round-1] = 100-values.percentage
            this.scorecard.roundwinner[values.round-1] = this.determineRoundWinners()
            this.scorecard.reasons[values.round-1] = values.chosenReason
            this.scorecard.comments[values.round-1] = values.comment
            if ( values.comment != ''){
                this.scorecard.comments[values.round-1] = values.comment
            } else {
                this.scorecard.comments[values.round-1] = 'None'
            }

            // refresh the scorecard dom data
            this.refreshScores();
        },

        refreshScores(){
            this.scorecard.round = this.scorecard.round++
            this.scorecard.round = this.scorecard.round--
            this.scorecard.f1scorecard.push('10')
            this.scorecard.f1scorecard.pop()
            this.scorecard.f2scorecard.push('10')
            this.scorecard.f2scorecard.pop()
        },  

        closeModal(){
            this.showPopup = false
            this.popupClasses = ''
        },

        switchText(){
            if (this.vidText == 'View Video'){
                this.vidText = 'Hide Video'
            } else {
                this.vidText = 'View Video'
            }
        }
        
    },


}
</script>

<style scoped>

.pulse {
    font-size: 1rem;
    transition: all .6s ease-in-out
}

.boxer-input {
    font-size: .9rem;
    width:130px;
}

.b1 input {
    color: #820000;
}
.b2 input {
    color: #004484;
}
.form-control:focus {
    color: #333;
    background-color: #fff;
    border-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 1);
}

.top0 {top: 0;}
.left0 {left: 0;}

.xsmall {
    font-size: 70%;
}

.bg-blue {
    background: #004484
}

.bg-red {
    background: #820000
}
</style>