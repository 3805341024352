<template>
    <div class="container bg-white" id="scoreModal">
        <div class="row pt-2 pb-1 border-bottom position-relative">
            <div class="col text-center">
                <h5>{{ editingRound ? 'EDIT' : 'SCORE' }} ROUND {{ editingRound ? selection.round : round+1 }}</h5>
            </div>
            <button type="button" class="close position-absolute"
                    style="right: 2rem;"
                        @click="closeModal()"><span>&times;</span></button>
        </div>

        <div class="row pt-2">
            <div class="col-6 text-center boxer-blue fon-weight-bold">
                Gennady Golovkin
            </div>
            <div class="col-6 text-center boxer-red fon-weight-bold">
                Saul Alverez
            </div>
        </div>

        <div class="row py-2 justify-content-center">
            <div class="col-6 d-flex justify-content-center align-items-center">
                <div class="pointer" @click="minusPoint(1)">
                    <i class="fas fa-minus" ></i>
                </div>
                <div class="score f1 text-white text-center py-2 px-4 mx-2">
                    <span class="">{{selection.f1}}</span>
                </div>
                <div class="pointer" @click="addPoint(1)">
                    <i class="fas fa-plus"></i>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center">
                <div class="pointer" @click="minusPoint(2)">
                    <i class="fas fa-minus"></i>
                </div>
                <div class="score f2 text-white text-center py-2 px-4 mx-2">
                    <span class="">{{selection.f2}}</span>
                </div>
                <div class="pointer" @click="addPoint(2)">
                    <i class="fas fa-plus"></i>
                </div>
            </div>

            <div class="col-12 col-sm-10 order-3 order-sm-2 mt-1 d-flex justify-content-center justify-content-sm-around align-items-center">
                <small class="py-2">
                    <i aria-hidden="true" class="fas fa-arrow-left mr-2" style="transform: rotate(45deg);"></i>
                    Score the round for each fighter
                    <i aria-hidden="true" class="fas fa-arrow-right ml-2" style="transform: rotate(-45deg);"></i>
                </small>
            </div>
        </div>

        <div class="row py-2 justify-content-center">
            <div class="col-2 col-xl-2 text-right">
                {{ f1percent }}%
            </div>
            <div class="col-8 col-xl-6 px-0">
                <div class="slidecontainer">
                    <input type="range" min="0" max="100" v-model="selection.percentage" 
                            class="slider range-slide" id="scoreRange" step="5" 
                            :data-percent="`range${selection.percentage}`"
                            >
                </div>
            </div>
            <div class="col-2 col-xl-2 text-left">
                {{ f2percent }}%
            </div>

            <div class="col-12 text-center lh-1">
                <small>
                    How close was this round? Adjust the slider in favour of the boxer who won the round.
                </small>
            </div>
        </div>
        
        <div class="row mt-2 py-2 justify-content-center">
            <div class="col-12 col-sm-10 mt-1 d-flex justify-content-center justify-content-sm-around align-items-center">
                <small class="text-center">
                    Select a reason why the round was won (or drawn)<br>
                    <i aria-hidden="true" class="fas fa-arrow-down mt-1"></i>
                </small>
            </div>
        </div>

        <div class="row justify-content-center py-2 bg-white">
            <div class="col-10 mx-auto text-center py-1 mb-1 reason pointer" 
                v-for="reason in reasons" 
                :key="reason" 
                :data-reason="reason"
                @click="selectReason(reason, $event)"
                :class="selection.chosenReason == reason ? 'selected' : ''"
                >
                <small>{{reason}}</small>
            </div>
        </div>

        <div class="row text-center py-2 bg-white ">
            <div class="col-12 pointer" @click="showComment = !showComment">
                <small><i class="fas fa-comment mr-2"></i> Add a comment?</small>
            </div>
            <div class="col-12 animate slideDown" v-if="showComment">
                <input class="form-control form-control-sm" v-model="selection.comment"
                    type="text" maxlength="195" placeholder="[optional] Add any additional comments..." />
            </div>
        </div>

        <div class="row text-center" v-if="showError">
            <div class="col-12">
                <div class="alert alert-danger">{{errorMsg}}</div>
            </div>
        </div>

        <div class="row py-2 text-center bg-white confirm-row">
            <div class="col-12">
                <button class="h5 btn btn-success confirm-btn" ref="confirm-btn" @click="confirmScore()">
                    <i class="far fa-check-square mr-2"></i> Confirm Score &amp; Reason
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'demo-score-popout',
    props: ['round'],

    data(){
        return {
            selection: {
                f1: 10,
                f2: 10,
                percentage: 50,
                chosenReason: '',
                comment: '',
                round: null
            },
            reasons: [
                'Knockdown',
                'Had opponent in trouble',
                'Dominated most of round',
                'On the front foot',
                'Controlled the action',
                'Landed cleaner or more effective shots',
                'Effective counter punching',
                'Good defensive work',
                'More active',
                'Opponent docked points',
                'Significant punch / combo',
                'Even round'
            ],
            errorMsg: '',
            showError: false,
            showComment: false,
            editingRound: false
        }
    },

    computed: {

        f1percent: function() {
            return this.selection.percentage
        },

        f2percent: function() {
            return 100-this.selection.percentage
        },

    },

    methods: {
        closeModal(){
            this.$emit('closeModal')
            
            if (this.editingRound){
                this.reset();
            }
        },

        addPoint(fighter){
            if ( fighter == 1 ) {
                if (this.selection.f1 < 10 ){
                    this.selection.f1 += 1;
                }
            } else {
                if (this.selection.f2 < 10 ) {
                    this.selection.f2 += 1;
                }
            }
        },

        minusPoint(fighter){
            if ( fighter == 1 ) {
                if (this.selection.f1 > 6 ){
                    this.selection.f1 -= 1;
                }
            } else {
                if (this.selection.f2 > 6 ){
                    this.selection.f2 -= 1;
                }
            }
        },

        selectReason(reason) {
            this.selection.chosenReason = reason
            
            // scroll to button
            let ipadbody = document.getElementsByClassName('ipad-body')[0]
            let confirmRow = document.getElementsByClassName('confirm-row')[0]
            ipadbody.scrollTo({top: confirmRow.offsetTop, behavior: 'smooth'})
        },

        confirmScore(){
            this.validateSelection()
            // Validate
            if (this.showError == false){

                if (this.selection.round){
                    this.$emit('editScores', this.selection)
                } else {
                    this.$emit('addScores', this.selection)
                }
                this.reset()
                this.closeModal()
            } else {
                this.showError = true
            }
        },

        validateSelection(){
            if ( this.selection.chosenReason == '' ) {  
                this.errorMsg = 'You must select a reason why the round was won'
                this.showError = true
                return
            } else {
                this.showError = false
            }

            if ( this.selection.f1 != this.selection.f2 ) {
                if ( this.selection.chosenReason == 'Even round' ){
                    this.errorMsg = 'Round Scores must be the same when selecting "Even round"'
                    this.showError = true
                }
            } else {
                if ( this.selection.chosenReason != 'Even round'){
                    this.showError = true
                    this.errorMsg = '"Even round" should be selected if scores are the same' 
                }
            }
        },

        reset(){
            this.selection = {
                round: null,
                f1: 10,
                f2: 10,
                percentage: 50,
                chosenReason: '',
                comment: ''
            }
            this.editingRound = false
        },

        editRound(values){
            this.editingRound = true
            this.selection.round = values.round
            this.selection.f1 = values.f1Score
            this.selection.f2 = values.f2Score
            this.selection.percentage = values.percent
            this.selection.chosenReason = values.reason
            this.selection.comment = values.comment
        },

    },
}
</script>
<style scoped>
    #scoreModal { transition: all .4s ease-in;}
    .pointer { cursor: pointer;}
    .b1 input {
        color: #004484;
        background: #cee4f9
    }
    .b2 input {
        color: #820000;
        background: #ffc9c9
    }
    .score { min-width: 75px;}
    .score.f1 { background: #004484;}
    .score.f2 { background: #820000;}
    .reason {background: #333; color: white; border: solid 1px #333}
    .reason:hover,
    .reason.selected {background: white; color: #333;}
    .lh-1 {line-height: 1rem;}


    @media (max-width: 665px) {
        .scorecard-menu ul li a {
            font-size:4vw
        }

        .scorecard-menu ul li.has-child ul li a {
            font-size: 3.2vw
        }

        section.header span.done {
            position: relative;
            top: 5px
        }

        .score-round input[type=number] {
            font-size: 16px
        }

        .score-round .scores .row:after {
            font-size: 15px
        }

        .confirm-reason,.confirm-score {
            font-size: 18px
        }
    }

    .slidecontainer,.slider {
        width: 100%
    }

    .slider {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 15px;
        outline: none;
        opacity: .9;
        transition: opacity .2s
    }

    .slider:hover {
        opacity: 1
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-color: #000;
        border-style: solid;
        border-width: 3px 2px;
        background: #fff;
        cursor: pointer
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #fff;
        cursor: pointer;
        border-radius: 0;
        border: solid
    }

    .range-slide {
        transition: all .3s ease-in-out;
        background: linear-gradient(90deg,#004484 50%,#820000 0)
    }

    .range0 {
        background: linear-gradient(90deg,#820000 100%,#820000 1000%)
    }

    .range5 {
        background: linear-gradient(90deg,#004484 5%,#820000 0)
    }

    .range10 {
        background: linear-gradient(90deg,#004484 10%,#820000 0)
    }

    .range15 {
        background: linear-gradient(90deg,#004484 15%,#820000 0)
    }

    .range20 {
        background: linear-gradient(90deg,#004484 20%,#820000 0)
    }

    .range25 {
        background: linear-gradient(90deg,#004484 25%,#820000 0)
    }

    .range30 {
        background: linear-gradient(90deg,#004484 30%,#820000 0)
    }

    .range35 {
        background: linear-gradient(90deg,#004484 35%,#820000 0)
    }

    .range40 {
        background: linear-gradient(90deg,#004484 40%,#820000 0)
    }

    .range45 {
        background: linear-gradient(90deg,#004484 45%,#820000 0)
    }

    .range50 {
        background: linear-gradient(90deg,#004484 50%,#820000 0)
    }

    .range55 {
        background: linear-gradient(90deg,#004484 55%,#820000 0)
    }

    .range60 {
        background: linear-gradient(90deg,#004484 60%,#820000 0)
    }

    .range65 {
        background: linear-gradient(90deg,#004484 65%,#820000 0)
    }

    .range70 {
        background: linear-gradient(90deg,#004484 70%,#820000 0)
    }

    .range75 {
        background: linear-gradient(90deg,#004484 75%,#820000 0)
    }

    .range80 {
        background: linear-gradient(90deg,#004484 80%,#820000 0)
    }

    .range85 {
        background: linear-gradient(90deg,#004484 85%,#820000 0)
    }

    .range90 {
        background: linear-gradient(90deg,#004484 90%,#820000 0)
    }

    .range95 {
        background: linear-gradient(90deg,#004484 95%,#820000 0)
    }

    .range100 {
        background: linear-gradient(90deg,#004484 100%,#820000 0)
    }

    [data-percent=range0] {
        background: linear-gradient(90deg,#820000 100%,#820000 1000%)
    }

    [data-percent=range5] {
        background: linear-gradient(90deg,#004484 5%,#820000 0)
    }

    [data-percent=range10] {
        background: linear-gradient(90deg,#004484 10%,#820000 0)
    }

    [data-percent=range15] {
        background: linear-gradient(90deg,#004484 15%,#820000 0)
    }

    [data-percent=range20] {
        background: linear-gradient(90deg,#004484 20%,#820000 0)
    }

    [data-percent=range25] {
        background: linear-gradient(90deg,#004484 25%,#820000 0)
    }

    [data-percent=range30] {
        background: linear-gradient(90deg,#004484 30%,#820000 0)
    }

    [data-percent=range35] {
        background: linear-gradient(90deg,#004484 35%,#820000 0)
    }

    [data-percent=range40] {
        background: linear-gradient(90deg,#004484 40%,#820000 0)
    }

    [data-percent=range45] {
        background: linear-gradient(90deg,#004484 45%,#820000 0)
    }

    [data-percent=range50] {
        background: linear-gradient(90deg,#004484 50%,#820000 0)
    }

    [data-percent=range55] {
        background: linear-gradient(90deg,#004484 55%,#820000 0)
    }

    [data-percent=range60] {
        background: linear-gradient(90deg,#004484 60%,#820000 0)
    }

    [data-percent=range65] {
        background: linear-gradient(90deg,#004484 65%,#820000 0)
    }

    [data-percent=range70] {
        background: linear-gradient(90deg,#004484 70%,#820000 0)
    }

    [data-percent=range75] {
        background: linear-gradient(90deg,#004484 75%,#820000 0)
    }

    [data-percent=range80] {
        background: linear-gradient(90deg,#004484 80%,#820000 0)
    }

    [data-percent=range85] {
        background: linear-gradient(90deg,#004484 85%,#820000 0)
    }

    [data-percent=range90] {
        background: linear-gradient(90deg,#004484 90%,#820000 0)
    }

    [data-percent=range95] {
        background: linear-gradient(90deg,#004484 95%,#820000 0)
    }

    [data-percent=range100] {
        background: linear-gradient(90deg,#004484 100%,#820000 0)
    }



</style>