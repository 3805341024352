<template>
    <section class="intro embed-responsive" :class="vidClass">
        <video autoplay muted loop id="myVideo" class="embed-responsive-item">
            <source src="/assets/video/Love-Coding.mp4" type="video/mp4">
        </video>

        <h1>
            <span class="intro-lead">Hello, I'm <strong class="ton">TON</strong>.</span> <br />
            <span class="intro-sub">I like to tinker with code and develop things.</span>
        </h1>

        <p class="mb-3 px-md-5 px-1">I am often engrossed in <strong>HTML, SCSS, JavaScript, PHP, Laravel, Livewire, Inertia, Tailwind, AlpineJS, Vue, BabylonJS &amp; Flutter</strong>.</p>
    </section>  
</template>

<script>
export default {
    name: 'Hero',

    data(){
        return{
            vidClass: 'embed-responsive-4by3'
        }
    },

    created(){
        if ( window.innerWidth > 768 ) { this.vidClass = 'embed-responsive-16by9'}
    },
}
</script>

<style>

</style>