<template>
    <div class="row justify-content-center text-center">
        <div class="col-12 col-md-6">
            <div class="d-flex flex-column flex-lg-row align-items-center mt-4 mb-2 justify-content-center">
                <h2 class="h3 text-xl-center d-inline-block d-inline-flex align-items-end">
                    HPL Motors
                </h2>   
                <small class="ml-2 align-self-stretch d-flex justify-content-center align-items-center"><span class="d-none d-lg-block mr-2">-</span> Work Project</small>
            </div>
            
            <p><a class="text-white" href="https://hplmotors.co.uk" target="_blank">hplmotors.co.uk</a> <i class="fas fa-external-link-alt"></i></p>
            <p>
                This existing client, a used car supermarket with multiple branches required an overhaul of their website frontend.
            </p>
            <p>
                Building upon the existing backend system I created a Vue frontend plus an API to fetch the required data.
            </p> 
            <p>
                This project was built using Nuxt and styled with the Vuetify framework for a modern, clean look plus fast and seamless transition 
                betweeen pages for a better user experience. 
            </p>
            <h3>Solutions</h3>
            <p>
                - Standalone Vue frontend utilising Nuxt and Vuetify <br>
                - Custom API integrated on existing backend system built using OctoberCMS<br>
            </p> 
        </div>
        <div class="col-12 col-md-6 col-xl-5 py-4">
            <ul class="nav nav-tabs" id="hplTabs" role="tablist">
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 1">
                    <a class="nav-link rounded-top" :class="activeTab == 1 ? 'active' : ''">New Design</a>
                </li>
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 2">
                    <a class="nav-link rounded-top" :class="activeTab == 2 ? 'active' : ''">Old Design</a>
                </li>
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 3">
                    <a class="nav-link rounded-top" :class="activeTab == 3 ? 'active' : ''">Made with Vue</a>
                </li>
            </ul>
            <div class="tab-content" id="HplTabContent">
                <div class="tab-pane fade" :class="activeTab == 1 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/hpl-motors-after.jpg" @click="launchModal('/img/portfolio/hpl-motors-after.jpg')">
                </div>
                <div class="tab-pane fade" :class="activeTab == 2 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/hpl-motors-before.jpg" @click="launchModal('/img/portfolio/hpl-motors-before.jpg')">
                </div>
                <div class="tab-pane fade" :class="activeTab == 3 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/hpl-vue.jpg" @click="launchModal('/img/portfolio/hpl-vue.jpg')">
                </div>
            </div>        
        </div>
    </div>  
</template>

<script>

export default {
    name: 'Project-Hpl',

    data(){
        return{
            activeTab: 1,
        }
    },

    methods: {

        launchModal(image){
            Event.$emit('openModal', image)
        }

    },


}
</script>

<style scoped>
    .nav-item { margin-right: 2px;}
    .nav-tabs {border-bottom: solid 1px rgb(128 142 156);}
    .nav-link
    {
        color: #fff;
        background: unset;
        border: solid 1px rgb(128 142 156);
    }

    .nav-link.active {
        background: rgb(128 142 156);
        border: solid 1px rgb(128 142 156);
        color: #fff;
    }

    .tab-content {
        padding: 0.5rem .2rem;
       
    }

     .cursor-pointer { cursor: pointer;}


</style>