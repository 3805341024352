<template>
    <div class="row justify-content-center text-center">
        <div class="col-12 col-md-6">
            <div class="d-flex flex-column flex-lg-row align-items-center mt-4 mb-2 justify-content-center">
                <h2 class="h3 text-xl-center d-inline-block d-inline-flex align-items-end">
                    PC Game Benchmark
                </h2>   
                <small class="ml-2 align-self-stretch d-flex justify-content-center align-items-center"><span class="d-none d-lg-block mr-2">-</span> Work Project</small>
            </div>
           
            <p><a class="text-white" href="https://pcgamebenchmark.com" target="_blank">pcgamebenchmark.com</a> <i class="fas fa-external-link-alt"></i></p>
            <p>
                I took over the development of PC Game Benchmark from a previous developer and have enhanced and added more functionality to this system 
                including enhanced affiliate capabilities and the integration of the Overwolf app data.
            </p>
            <p>
                This system is a custom PHP platform comprised of separate frontend and backend CMS systems as well as a number of C# programs which 
                serves as a web scraper, suppling the site with data from various sources.
            </p> 
            <p>
                A fully customised system created in PHP, HTML & JavaScript running on an MySQL database. 
            </p>

 
        </div>
        <div class="col-12 col-md-6 col-xl-5 py-4">
            <ul class="nav nav-tabs" id="pcgbTaSb" role="tablist">
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 1">
                    <a class="nav-link rounded-top" :class="activeTab == 1 ? 'active' : ''">Frontend</a>
                </li>
                <li class="nav-item cursor-pointer" v-on:click="activeTab = 2">
                    <a class="nav-link rounded-top" :class="activeTab == 2 ? 'active' : ''">Backend CMS</a>
                </li>
               
            </ul>
            <div class="tab-content" id="pcgbTabContent">
                <div class="tab-pane fade" :class="activeTab == 1 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/pcgb-frontend.png" @click="launchModal('/img/portfolio/pcgb-frontend.png')">
                </div>
                <div class="tab-pane fade" :class="activeTab == 2 ? ' show active' : ''">
                    <img class="img-fluid cursor-pointer" src="/img/portfolio/pcgb-backend.png" @click="launchModal('/img/portfolio/pcgb-backend.png')">
                </div>
            </div>        
        </div>
    </div>  
</template>

<script>

export default {
    name: 'Project-Pcgb',

    data(){
        return{
            activeTab: 1,
        }
    },

    methods: {

        launchModal(image){
            Event.$emit('openModal', image)
        }

    },


}
</script>

<style scoped>
    .nav-item { margin-right: 2px;}
    .nav-tabs {border-bottom: solid 1px rgb(128 142 156);}
    .nav-link
    {
        color: #fff;
        background: unset;
        border: solid 1px rgb(128 142 156);
    }

    .nav-link.active {
        background: rgb(128 142 156);
        border: solid 1px rgb(128 142 156);
        color: #fff;
    }

    .tab-content {
        padding: 0.5rem .2rem;
       
    }

     .cursor-pointer { cursor: pointer;}


</style>