<template>
  <div class="row text-body px-0 py-3 py-md-5">
    <div class="container py-2 py-md-3">
        <div class="row align-items-center">
            <div class="col-12 text-light">
                <h2 class="mb-2 mb-md-4">What I'm listening to</h2>
                <p>My Spotify Daily Mix playlists will give you a glance into my current musical listening as I bury my mind deep in code and problem solving.</p>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1E3889iXPeqqEL" width="100%" height="300" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe> 
                    </div>
                    <div class="col-12 col-md-6">
                        <iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1E39chb2XGFTov" width="100%" height="300" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>