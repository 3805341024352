<template>
  <div id="app">
    <Header></Header>
    <Hero></Hero>

    <main class="container-fluid">
      <Projects></Projects>

      <Employment></Employment>

      <Spotify></Spotify>

      <Connect></Connect>
    </main>

  </div>
</template>

<script>
import Header from './components/Header.vue'
import Hero from './components/Hero.vue'
import Projects from './components/Projects.vue'
import Employment from './components/Employment.vue'
import Spotify from './components/Spotify.vue'
import Connect from './components/Connect.vue'

export default {
  name: 'App',
  components: {
    Header,
    Hero,
    Projects,
    Employment,
    Spotify,
    Connect
  }
}
</script>
