<template>
    <div class="ipad h-100 p-3 bg-black rounded-10">
        <div class="ipad-body bg-white h-100 text-body position-relative">
            <demo-scorecard></demo-scorecard>
        </div>
    </div>
</template>

<script>
import DemoScorecard from './Demo/Demo-Scorecard.vue'
export default {
    name: 'Fns-Demo',
    components: {
        DemoScorecard
    }
}
</script>


<style>

.ipad {
    min-height: 500px;
    max-height: 500px;
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    font-family: 'Roboto';
    border-radius: 5px;
}

.ipad .ipad-body {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.ipad .ipad-body::-webkit-scrollbar {
    display: none
}

.ipad .demo-popup {
    z-index: 5;
    width: 100%;
    height: 100%
}

.bg-black {
    background-color: #333
}

.start-demo.bg-green {
    cursor: pointer;
    background-color: #588e78
}


.bg-green {
    cursor: pointer;
    background-color: #588e78;
}

.bg-green:hover {background-color: #8fada0;}

.start-demo.bg-green:hover {
    background-color: #8fada0
}

.boxer-img img {
    max-width: 120px
}

.boxer-avatar {
    max-width: 80px!important
}

.demo-index .fight,.demo-index .fight:hover {
    transition: all .2s ease-in
}

.demo-index .fight:hover {
    background: #f8f9fa
}

.demo-index .fight:hover img {
    filter: sepia(1)
}

.demo-index .fight img {
    max-width: 100%;
    height: auto
}


.demo-vid {
    display: none;
    position: absolute;
    z-index: 1;
    right: 0;
    height: auto;
    width: 45%
}

.demo-vid .close {
    display: inline-block;
    top: 0;
    position: absolute;
    background: #333;
    z-index: 2;
    text-align: center;
    font-size: .8rem;
    font-weight: 400;
    color: #fff;
    opacity: 1;
    left: -60px;
    transform: rotate(0);
    transition: transform .3s ease-in
}

.demo-vid .close.closed svg {
    transform: rotate(180deg);
    transition: transform .3s ease-in
}

.demo-vid .close svg {
    font-size: 2rem
}

.demo-vid .embed-responsive {
    width: 100%;
    height: auto;
    max-width: 100%
}
   
</style>