<template>
  <header class="row navbar-fixed-top no-gutters">
    <section class="col-12 col-md-4 order-1 order-md-2 brand justify-content-between p-0">
        <h1 class="d-block ml-2 mx-md-auto">Anton Hutchinson</h1>

        <button class="d-block mr-2 d-md-none border-0 bg-transparent text-white" type="button" 
            data-toggle="collapse" data-target="#collapseMenu" aria-expanded="false" 
            aria-controls="collapseMenu"><i class="fa fa-bars"></i></button>
    </section>

    <section class="col-sm-4 col-xs-12 order-2 order-md-1 left-nav ">
        <nav id="collapseMenu" class="h-100 collapse">
            <ul>
                <li>
                    <a type="button" data-toggle="modal" data-target="#exampleModalCenter" href="#projects">Projects</a>
                </li>
                <li>
                    <a href="#work">Employment</a>
                </li>
            </ul>
        </nav>
    </section>
    <section class="col-12 col-sm-4 order-3 right-nav ">
        <nav id="collapseMenu" class="h-100 collapse">
            <ul>
                <li>
                    <a href="https://www.linkedin.com/in/anton-hutchinson/">Linked In</a>
                </li>
                <li>
                    <a href="#contact">Contact</a>
                </li>
            </ul>
        </nav>
    </section>
</header>
</template>

<script>
export default {
  name: 'Header',
  
}
</script>
